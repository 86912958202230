import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DocContext from '../reduxContainer/context/DocContext';

const LeftSideBar = () => {
    const navigate = useNavigate();
    const docContext = useContext(DocContext);
    const { fetchDocuments } = docContext;

    const loadDocuments = async (e, category) => {
        e.preventDefault();
        await fetchDocuments(category);
        navigate('/dataContent');
        window.scrollTo(0, 0);
    }
    return (
        <div className="col-sm-2 leftsidenav link-opacity-75-hover">
            <table className='table table-striped table-bordered table-hover'>
                <thead  className='thead-dark'>
                    <tr><th className='listHeading'><h6>Most Searched Items</h6></th></tr>
                </thead>
                <tbody>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Triveni')}>List of Triveni</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'ACP Notification')}>ACP Notification</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'GEM')}>GEM Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Gradation List')}>Gradation List</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'List of Divisions')}>List of Divisions</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Books')}>Books(FHB, etc.)</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'GST')}>GST Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'SBD')}>SBD, TI,T2, T3 etc.</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Gpf')}>GPF Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Security')}>Security: EMD, SD, APS</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'LTC')}>LTC Orders</Link></td></tr>
                </tbody>
            </table>
        </div>
    );
}

export default LeftSideBar;
