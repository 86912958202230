import React from 'react';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';

const Alert = () => {
    const alertContext = useContext(AlertContext);
    const { alert } = alertContext;

    const isMobile = matchMedia('(max-width: 576px)').matches;

    var width = '50';

    if (isMobile) {
        width = 100;
    }
    return (
        <div className="alert row">
            {alert && <div className={`w-${width} alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                {alert.message}
            </div>}
        </div>
    );
}

export default Alert;

/*const capitalize = (word) => {
    if (word === 'danger') {
        word = "error";
    }
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}

const htmlAlert = (alertMessage) => {
    window.alert(alertMessage);
}*/