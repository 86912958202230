import React from 'react';
import SalaryCalculatorContentBar from './SalaryCalculatorContentBar';
import LeftSideBar from '../../components/LeftSideBar';
import RightSideBar from '../../components/RightSideBar';

const SalaryCalculatorMiddle = () => {
  return (
    <div>
      <div className="middleBar container-fluid text-center my-2">
            <div className="row content">
                <LeftSideBar />
                <SalaryCalculatorContentBar />
                <RightSideBar />
            </div>
        </div>
    </div>
  );
}

export default SalaryCalculatorMiddle;
