import React from 'react';
import ImportantContentBar from './ImportantContentBar';

const ImportantMiddle = () => {
    return (
        <div>
          <div className="middleBar container-fluid text-center my-2">
                <div className="row content">
                    <ImportantContentBar />
                </div>
            </div>
        </div>
      );
}

export default ImportantMiddle;
