import React, { useContext, useState } from 'react';
import axios from 'axios';
import AlertContext from '../reduxContainer/context/AlertContext';
import CategorySelector from './CategorySelector';

const FileDeleter = () => {
    const [selectValue, setSelectValue] = useState("");
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;

    const handleDeletion = async (e) => {
        e.preventDefault();
        const result = await axios.delete(`${host}/document/deleteCategoryDocsByName`, { data: { categoryType: selectValue }}, { headers: { 'content-type': 'application/json' } });
        console.log(result.data);
        window.scrollTo(0, 0);
        if (result.status === 200) {
            console.log('result.data: ', result.data.desc);
            window.scrollTo(0, 0);
            showAlert(`${result.data.desc}`, "success");
        } else {
            showAlert(`${result.error}`, "danger");
        }
        clearFormData(e);
    }

    const clearFormData = (e) => {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
    }

    return (
        <div className='container'>
            <form method='POST' encType="multipart/form-data" onSubmit={(e) => handleDeletion(e)} className='fileUploadForm'>
                <div className="fileUploadForm">
                <CategorySelector selectValue = {selectValue} setSelectValue = {setSelectValue}/>
                    <button type="submit" className="btn btn-success mb-3">Delete Category</button>
                </div>
            </form>
        </div>
    );
}

export default FileDeleter;
