import React, { useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import MiddleBar from './MiddleBar';
import Alert from '../components/Alert';
import AlertContext from '../reduxContainer/context/AlertContext';

const Home = () => {
  const alertContext = useContext(AlertContext);
  const { alert } = alertContext;
  return (
    <div>
      <Header />
      {alert && (<Alert />)}
      {!alert && (<SearchBar />)}
      <MiddleBar />
      <Footer />
    </div>
  );
}

export default Home;
