import React from 'react';
import LeftSideBar from '../components/LeftSideBar';
import RightSideBar from '../components/RightSideBar';
import MiddleDashBoardBar from './MiddleDashBoardBar';

const MiddleDashBoardArea = () => {
    return (
        <div className="middleBar container-fluid text-center my-2">
            <div className="row content">
                <LeftSideBar/>
                <MiddleDashBoardBar />
                <RightSideBar/>
            </div>
        </div>
    );
}

export default MiddleDashBoardArea;
