import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DocContext from '../reduxContainer/context/DocContext';

const RightSideBar = () => {
    const navigate = useNavigate();
    const docContext = useContext(DocContext);
    const { fetchDocuments } = docContext;

    const loadDocuments = async (e, category) => {
        e.preventDefault();
        await fetchDocuments(category);
        navigate('/dataContent');
    }

    return (
        <div className="col-sm-2 rightsidenav link-opacity-75-hover">
            <table className='leftNavMobileView table table-striped table-bordered table-hover'>
                <thead className='thead-dark'>
                    <tr><th className='listHeading'><h6>Most Searched Items</h6></th></tr>
                </thead>
                <tbody>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Triveni')}>List of Triveni</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'ACP Notification')}>ACP Notification</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'GEM')}>GEM Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Gradation List')}>Gradation List</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'List of Divisions')}>List of Divisions</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Books')}>Books(FHB, etc.)</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'GST')}>GST Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'SBD')}>SBD, TI,T2, T3 etc.</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Gpf')}>GPF Orders</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'Security')}>Security: EMD, SD, APS</Link></td></tr>
                    <tr><td><Link to="/loadCategoryData" onClick={(e) => loadDocuments(e, 'LTC')}>LTC Orders</Link></td></tr>
                </tbody>
            </table>
            <table className='table table-striped table-bordered table-hover'>
                <thead className='thead-dark'>
                    <tr><th className='listHeading'><h6>Important Links</h6></th></tr>
                </thead>
                <tbody className='navTable'>
                    <tr><td><Link to="https://uppwd.gov.in/en/governmentorders?ctypeid=3&cd=NAA4ADgA" target="_blank">UP PWD GO's</Link></td></tr>
                    <tr><td><Link to="http://shasanadesh.up.gov.in/" target="_blank">List of Shasanadesh</Link></td></tr>
                    <tr><td><Link to="https://cag.gov.in/ae/allahabad-ii/en/ae-circulars-office-orders?cat=854" target="_blank">AG UP latest Orders</Link></td></tr>
                    <tr><td><Link to="https://cag.gov.in/ae/allahabad/en/page-ae-allahabad-download-various-forms-for-official-purposes" target="_blank">Various forms(TA, LTC, Medclaim etc.)</Link></td></tr>
                </tbody>
            </table>
        </div>
    );
}

export default RightSideBar;
