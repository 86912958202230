import React, { useEffect } from 'react';
import FileUploader from './FileUploader.js';
import CategoryUploader from './CategoryUploader.js';
import SyncBulkUpload from './SyncBulkUpload.js';
import FileDeleter from './FileDeleter.js';
import Login from '../screens/Login.js';
import InfoCounter from './InfoCounter.js';
import ScrollToTop from 'react-scroll-to-top';

const MiddleDashBoardBar = () => {
    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    return (
        <div className="middleDashBoard centre col-sm-8">
            {localStorage.getItem('token') ?
                (<div className="dashboardHeading">
                    <div className="dashBoardBox mt-3">
                        <InfoCounter />
                    </div>
                    <div className="dashBoardBox">
                        <h5 className='dashboardSubHeading mb-2'>Add a new Category</h5>
                        <CategoryUploader />
                    </div>
                    <div className="dashBoardBox">
                        <h5 className='dashboardSubHeading mb-2'>Upload document(s) to a selected category.</h5>
                        <FileUploader />
                    </div>
                    <div className="dashBoardBox">
                        <h5 className='dashboardSubHeading mb-2'>Select a Category to delete. (<small><u>Note</u>: All linked documents shall also be deleted.)</small></h5>
                        
                        <FileDeleter />
                    </div>
                    <div className="dashBoardBox">
                        <h5 className='dashboardSubHeading mb-2'>Select this option to Sync - Bulk Upload documents.</h5>
                        <SyncBulkUpload />
                    </div>
                </div>) :
                (<><div className="centre fs-4 SubHeading">
                    Login with Administrator to access Dashboard</div>
                    <div className='dashBoardLogin'>
                        <Login width="75" marginTop="0" previousPage="/dashboard" excludeAlert='true' excludeFooter='true' marginLeft="90" positionMiddle = ""/>
                    </div>
                </>)
            }
            <div>
                <ScrollToTop smooth />
            </div>
        </div>
    );
}

export default MiddleDashBoardBar;
