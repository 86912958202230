import axios from 'axios';
import React, { useContext } from 'react';
import AlertContext from '../reduxContainer/context/AlertContext';

const SyncBulkUpload = () => {
  const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
  const alertContext = useContext(AlertContext);
  const { showAlert } = alertContext;

  const handleUpload = async (e) => {
    try {
      e.preventDefault();
      const result = await axios.post(`${host}/file/syncRepository`, { headers: { 'content-type': 'application/json' } });
      window.scrollTo(0, 0);
      if (result.status === 201) {
        showAlert(result.data.desc, "success");
      } else {
        showAlert(`${result.data.error}`, "danger");
      }
    } catch (error) {
      window.scrollTo(0, 0);
      console.log('Error in SyncBulkUpload | message: ', error.message);
      showAlert('Internal Error received from Server', "danger");
    }

  }

  return (
    <div className='pb-2'>
      <form method='POST' onSubmit={(e) => handleUpload(e)} className='fileUploadForm'>
        <button type="submit" className="btn btn-success">Sync&nbsp;-&nbsp;bulk&nbsp;Upload</button>
      </form>
    </div >
  );
}

export default SyncBulkUpload;
