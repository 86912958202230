import React from 'react';
import AboutContentBar from './AboutContentBar';
import LeftSideBar from '../components/LeftSideBar';
import RightSideBar from '../components/RightSideBar';

const AboutMiddle = () => {
  return (
    <div>
      <div className="middleBar container-fluid text-center my-2">
            <div className="row content">
                <LeftSideBar />
                <AboutContentBar />
                <RightSideBar />
            </div>
        </div>
    </div>
  );
}

export default AboutMiddle;
