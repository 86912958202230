import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DocContext from '../reduxContainer/context/DocContext';
import ReactPaginate from 'react-paginate';
import AlertContext from '../reduxContainer/context/AlertContext';
import SocialShare from '../components/SocialShare';

const MiddleDataContentBar = () => {

    const capitalize = (word) => {
        if (word === 'danger') {
            word = "error";
        }
        const lower = word.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    }

    let i = 1;
    const itemsPerPage = 8;
    const docContext = useContext(DocContext);
    let { documents, category } = docContext;
    let [itemsData, setItemsData] = useState(documents);
    let items = documents;
    const [pageCount, setPageCount] = useState(0);
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;
    useEffect(() => {
        (async () => {
            window.scrollTo(0, 0);
            //console.log('documents now: ', documents);
            // eslint-disable-next-line
            items = documents;
            //console.log('items now: ', items);
            const totalItems = documents.length;
            setPageCount(Math.ceil(totalItems / itemsPerPage));
            items = documents.slice(0, itemsPerPage);
            setItemsData(items);
        })();
    }, [documents, items]);

    const handlePageClick = (data) => {
        let currentPage = data.selected + 1;
        const lastIndex = currentPage * itemsPerPage;
        const firstIndex = lastIndex - itemsPerPage;
        console.log('currentPage: ', currentPage, ', firstIndex: ', firstIndex, ', lastIndex: ', lastIndex);
        items = documents.slice(firstIndex, lastIndex);
        setItemsData(items);
    }

    const handleDocClick = (e, remotePath) => {
        e.preventDefault();
        let newWin = window.open(remotePath, "_blank", "width=800,height=600, rel=noopener");
        newWin.focus();
    }


    const handleCopyToClipBoard = (remotePath) => {
        navigator.clipboard.writeText(encodeURI(remotePath));
        showAlert(`Link copied to clipboard. You can share it with anyone now.`, "info");
    }

    const clickDocSearch = () => {
        document.getElementById('searchId').click();
    }

    const isMobile = matchMedia('(max-width: 576px)').matches;

    if (!isMobile) {
        return (
            <div className="centre col-sm-8">
                <h4 className='SubHeading fs-4'>{category} documents</h4>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th className='text-start'>Shasanadesh/Order/Notification</th>
                            <th>View</th>
                            <th>Download</th>
                            <th>Copy</th>
                            <th>Share</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemsData.length === 0 ? (<tr><td colSpan="6"><div className="noData">No document found on Page. Press <Link class="bg-success text-white" id="docSearchId" onClick={clickDocSearch}>Enter</Link> to Search in repository.</div></td></tr>) :
                            itemsData.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td>{i++}</td>
                                        <td className='text-start'><Link to={element.remotePath} target="_blank" download>{capitalize(element.fileName)}</Link></td>
                                        <td><Link to={element.destination} onClick={(e) => handleDocClick(e, element.remotePath)}>Open</Link></td>
                                        <td><Link to={element.remotePath} target="_blank" download><i className="fa-solid fa-download"></i></Link></td>
                                        <td><Link to="#" onClick={() => handleCopyToClipBoard(element.remotePath)}><i className="fa-solid fa-share"></i></Link></td>
                                        <td><SocialShare url = {element.remotePath} title ={element.fileName}/></td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                {documents.length > itemsPerPage - 1 && <div className="pagination">
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center text-danger'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>}
            </div>
        );
    } else {
        return (
            <div className="tableBox centre col-sm-8">
                <h4 className='SubHeading fs-4'>{category} documents</h4>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th className='text-start'>Shasanadesh/Order</th>
                            <th>Share</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemsData.length === 0 ? (<tr><td colSpan="6"><div className="noData">No document found on Page. Press <Link class="bg-success text-white" id="docSearchId" onClick={clickDocSearch}>Enter</Link> to Search in repository.</div></td></tr>) :
                            itemsData.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td>{i++}</td>
                                        <td className='text-start'><Link to={element.remotePath} target="_blank" download>{capitalize(element.fileName)}</Link></td>
                                        {/* <td><Link to="#" onClick={() => handleCopyToClipBoard(element.remotePath)}><i className="fa-solid fa-share"></i></Link></td> */}
                                        <td><SocialShare url = {element.remotePath} title ={element.fileName}/></td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                {(documents.length > itemsPerPage - 1) && <div className="pagination">
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center text-danger'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>}
            </div>
        );
    }
}

export default MiddleDataContentBar;
