import React from 'react';
import ImportantMain from './ImportantMain';

const ImportantContentBar = () => {
    return (
        <div className="col-sm-8">
            <ImportantMain />
        </div>
      );
}

export default ImportantContentBar;
