import React, { useContext, useEffect, useState } from 'react';
import CatContext from '../reduxContainer/context/CategoryContext';
import Select from 'react-select';

const CategorySelector = (props) => {
    let [options, setOptions] = useState([]);

    const catContext = useContext(CatContext);
    let { loading, categories, fetchCategories } = catContext;

    // eslint-disable-next-line
    useEffect(() => {
        (async () => {
            await fetchCategories();
            populateSelect();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const populateSelect = () => {
        setOptions(null);
        const catOptions = []
        categories.forEach((cat) => {
            let value = cat.categoryType;
            let label = cat.categoryType;
            catOptions.push({ value: value, label: label });
        });
        setOptions(catOptions);
        return options;
    }

    if (loading) {
        return (<center><div><div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
        </div></div></center>)
    } else {
        return (
            <Select id="category"
                options={options}
                defaultValue={props.selectValue}
                name="type"
                className="mb-3 mx-3"
                placeholder="Search Category"
                onChange={(e) => { props.setSelectValue(e.value) }}
                isSearchable
                noOptionsMessage={() => "No Category found"}
                required
                styles={{
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '200px !important;',
                        fontSize: '1',
                        fontWeight: 'lighter'
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '200px !important;',
                        fontPalette: 'normal',
                        fontSize: '1',
                        fontWeight: 'lighter'
                    })
                }}>
            </Select>
        );
    }
}

export default CategorySelector;
