import React from 'react';
import AboutMain from './AboutMain';

const AboutContentBar = () => {
  return (
    <div className="col-sm-8">
        <AboutMain />
    </div>
  );
}

export default AboutContentBar;
