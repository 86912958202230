import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';
//import DocContext from '../reduxContainer/context/DocContext';


const Navbar = () => {
    //const docContext = useContext(DocContext);
    // { fetchDocuments } = docContext;

    /*     const loadDocuments = async (e, category) => {
            e.preventDefault();
            await fetchDocuments(category);
            navigate('/dataContent');
        } */

    const isMobile = matchMedia('(max-width: 576px)').matches;
    console.log('isMobile: ', isMobile);

    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.scrollTo(0, 0);
        showAlert("Logged out successfully.", "success");
        navigate('/');
    };

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">DaoAssist</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">About</Link>
                            </li>
                            {/* <li className="nav-item"  data-bs-toggle='collapse' data-bs-target='.navbar-collapse.show'>
                                <Link className="nav-link" to="/orders" onClick={(e) => loadDocuments(e, 'Gradation List')}>Gradation List</Link>
                            </li>
                            <li className="nav-item"  data-bs-toggle='collapse' data-bs-target='.navbar-collapse.show'>
                                <Link className="nav-link" to="/divisions" onClick={(e) => loadDocuments(e, 'List of Divisions')}>List of Divisions</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/salcalculator">Salary&nbsp;Calculator</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contactus">Contact&nbsp;Us</Link>
                            </li>
                            {isMobile && <li className="nav-item">
                                <Link className="nav-link" to="/topdocuments">Top&nbsp;Documents&nbsp;&&nbsp;Links</Link>
                            </li>
                            }

                            {!localStorage.getItem('token') ?
                                (<div className='signinBlock'><li className="nav-item">
                                    <Link className="nav-link" to="/signin">Sign In</Link>
                                </li>&nbsp;&nbsp;
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/signup">Create new Account</Link>
                                    </li></div>
                                ) :
                                (<div className='signinBlock'><li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Welcome {localStorage.getItem('user')}
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/signin" onClick={handleLogout}>Logout</Link></li>
                                    </ul>
                                </li>
                                </div>)}
                            {localStorage.getItem('token') & <li className="nav-item">
                                <Link className="nav-link" to="/signup">Create Account</Link>
                            </li>}
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;
