import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer className="bg-light text-lg-start">
        <div className="footer p-3">
          <Link className="text-success" to="/about" >About&nbsp;Us </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          DaoAssist © 2023 Copyright: &nbsp;
          <a className="text-dark" href="https://daoassist.in/"><u>https://daoassist.in</u></a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className="text-success" to="/privacy" >Privacy Policy </Link>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
