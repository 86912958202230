import React, { useEffect, useState } from 'react';
import PdfDocument from '../../components/PdfDocument';

const SalaryCalculatorMain = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [basicSal, setBasicSal] = useState(0);
    const [da, setDa] = useState(0);
    const [hra, setHra] = useState(0);
    const [ta, setTa] = useState(0);
    const [daOnTa, setDaOnTa] = useState(0);
    const [cityType, setCityType] = useState("Z");
    const [grossSalary, setGrossSalary] = useState(0);
    const [npsGpf, setNpsGpf] = useState(0);
    const [gis, setGis] = useState(0);
    const [otherDeductions, setOtherDeductions] = useState(0);
    const [otherAllowances, setotherAllowances] = useState(0);
    const [netSalary, setNetSalary] = useState(0);

    const setBasicValues = (basicSalValue) => {
        setBasicSal(basicSalValue);
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setDaValues = (daValue) => {
        setDa(daValue);
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setHraValues = (hraValue) => {
        setHra(hraValue);
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setTaValues = (taValue) => {
        setTa(taValue);
        setDaOnTa(taValue * da / 100);
        document.getElementById('daOnTa').value = taValue * da / 100;
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setotherAllowancesValues = (otherAllowancesValue) => {
        setotherAllowances(otherAllowancesValue);
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setNpsGpfValues = (npsGpfPersentage) => {
        setNpsGpf(Math.round((parseInt(basicSal) + parseInt(basicSal * da / 100)) * parseInt(npsGpfPersentage) / 100));
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setDaOnTaValues = (daOnTaValue) => {
        setDaOnTa(daOnTaValue);
        calculateGrossSalary();
        calculateNetSalary();
    }

    const setGisValues = (gisValue) => {
        setGis(gisValue);
        calculateNetSalary();
    }

    const setOtherDeductionsValues = (otherDeductionsValue) => {
        setOtherDeductions(otherDeductionsValue);
        calculateNetSalary();
    }

    const calculateGrossSalary = () => {
        //console.log('basicSal: ', basicSal);
        var grossSal = parseInt(basicSal) + parseInt(basicSal * da / 100) + parseInt(basicSal * hra / 100) + parseInt(ta) + parseInt(ta * da / 100) + parseInt(otherAllowances);
        setGrossSalary(grossSal);
        //console.log('grossSal: ', grossSal);
        return grossSal;
    }

    const calculateNetSalary = () => {
        //console.log('basicSal: ', basicSal);
        var grossSal = parseInt(basicSal) + parseInt(basicSal * da / 100) + parseInt(basicSal * hra / 100) + parseInt(ta) + parseInt(ta * da / 100) + parseInt(otherAllowances);
        var netSal = parseInt(grossSal) - parseInt(npsGpf) - parseInt(gis) - parseInt(otherDeductions);
        setNetSalary(netSal);
        //console.log('netSal: ', netSal);
        return netSal;
    }

    const calculateSalary = () => {
        calculateGrossSalary();
        calculateNetSalary();
        document.getElementById("salarytable").style.display = "block";
        document.getElementById("downloadSalarySlip").style.display = "block";

    }

    const calculateHra = (cityType) => {
        setCityType(cityType);
        if (da === '') {
            setDa(46);
        }
        if (da >= 25 || da < 50) {
            if (cityType === 'X') {
                setHra(27);
                document.getElementById('hra').value = 27;
            }
            if (cityType === 'Y') {
                setHra(18);
                document.getElementById('hra').value = 18;
            }
            if (cityType === 'Z') {
                setHra(9);
                document.getElementById('hra').value = 9;
            }
        }

        if (da < 25) {
            if (cityType === 'X') {
                setHra(24);
                document.getElementById('hra').value = 24;
            }
            if (cityType === 'Y') {
                setHra(16);
                document.getElementById('hra').value = 16;
            }
            if (cityType === 'Z') {
                setHra(8);
                document.getElementById('hra').value = 8;
            }
        }

        if (da >= 50) {
            if (cityType === 'X') {
                setHra(30);
                document.getElementById('hra').value = 30;
            }
            if (cityType === 'Y') {
                setHra(20);
                document.getElementById('hra').value = 20;
            }
            if (cityType === 'Z') {
                setHra(10);
                document.getElementById('hra').value = 10;
            }
        }

        calculateGrossSalary();
        calculateNetSalary();
    }

    return (
        <div>
            <div className="container">
                <form className="salaryForm" method="post">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="centre SubHeading p-1 mb-2">
                                <h3>Salary Calculator</h3>
                            </div>
                            <h5>Salary and Allowances</h5>
                            <div className="d-flex">
                                <div className="form-group mb-1 me-3">
                                    <input type="text" name="salary" id="salary" className="form-control" placeholder="Basic Salary &#8377;" onInput={(e) => setBasicValues(e.target.value)} required />
                                </div>
                                <div className="form-group mb-1">
                                    <input type="text" name="da" id="da" className="form-control" placeholder="DA/DR in %" onChange={(e) => setDaValues(e.target.value)} required />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group mb-1 me-5">
                                    <select className="form-select" aria-label="Default select example" onChange={(e) => calculateHra(e.target.value)} required>
                                        <option defaultValue>City Select&nbsp;&nbsp;</option>
                                        <option value="X">X</option>
                                        <option value="Y">Y</option>
                                        <option value="Z">Z</option>
                                    </select>
                                </div>
                                <div className="form-group mb-1">
                                    <input type="text" name="hra" id="hra" className="form-control" placeholder="HRA in %" onChange={(e) => setHraValues(e.target.value)} required />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group mb-1 me-2">
                                    <input type="text" name="ta" id="ta" className="form-control" placeholder="Transport Allowance &#8377;" onChange={(e) => setTaValues(e.target.value)} />
                                </div>
                                <div className="form-group mb-1">
                                    <input type="text" name="otherAllowances" id="otherAllowances" className="form-control" placeholder="Other Allowance &#8377;" onChange={(e) => setotherAllowancesValues(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group mb-1">
                                <input type="text" name="daOnTa" id="daOnTa" className="form-control" placeholder="DA on TA &#8377;" onChange={(e) => setDaOnTaValues(e.target.value)} />
                            </div>
                            <h5>Deductions</h5>
                            <div className="form-group mb-1">
                                <input type="type" name="npsgpf" id="npsgpf" className="form-control" placeholder="NPS/GPF in %" onChange={(e) => setNpsGpfValues(e.target.value)} />
                            </div>
                            <div className="form-group mb-1">
                                <input type="type" name="gis" id="gis" className="form-control" placeholder="GIS Deduction &#8377;" onChange={(e) => setGisValues(e.target.value)} />
                            </div>
                            <div className="form-group mb-2">
                                <input type="type" name="otherDeductions" id="otherDeductions" className="form-control" placeholder="otherDeductions &#8377;" onChange={(e) => setOtherDeductionsValues(e.target.value)} />
                            </div>
                            <div className="btn btn-success mb-3" onClick={calculateSalary}>Calculate Salary</div>
                        </div>
                        <div className="col-md-6">
                            <table id="salarytable" className="salaryTable table w-auto table-striped table-success table-hover" style={{ display: 'none' }}>
                                <tbody>
                                    <tr><td colSpan="2" className="table-active">Basic Salary</td>
                                        <td width="150px">{basicSal}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">DA/DR</td>
                                        <td>{basicSal * da / 100}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">HRA</td>
                                        <td>{basicSal * hra / 100}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">TA (Transport Allowance)</td>
                                        <td>{ta}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">DA on TA</td>
                                        <td>{ta * da / 100}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">Other Allowance</td>
                                        <td>{otherAllowances}</td> </tr>
                                    <tr><td colSpan="2" className="table-active"><b>Gross Salary</b></td>
                                        <td>{grossSalary}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">NPS/GPF</td>
                                        <td>{npsGpf}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">GIS</td>
                                        <td>{gis}</td> </tr>
                                    <tr><td colSpan="2" className="table-active">Other Deductions</td>
                                        <td>{otherDeductions}</td> </tr>
                                    <tr><td colSpan="2" className="table-active"><b>Net Salary</b></td>
                                        <td>{netSalary}</td> </tr>
                                </tbody>
                            </table>
                            <div id="downloadSalarySlip" className="mb-3" style={{ display: 'none' }}>
                                <PdfDocument basicSal={basicSal} da={da} ta={ta} hra={hra} daOnTa={daOnTa} grossSalary={grossSalary} npsGpf={npsGpf} gis={gis} otherAllowances={otherAllowances} otherDeductions={otherDeductions} netSalary={netSalary} />
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SalaryCalculatorMain;
