import { useState } from "react";
import axios from 'axios';
import CatContext from "./CategoryContext";

const CategoryState = (props) => {
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
    let initialCats = [];
    const [categories, setCategories] = useState(initialCats);
    const [catCount, setCatCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchCategories = async () => {
        try {
            const response = await axios.post(`${host}/category/getAllCategories`, { headers: { 'content-type': 'application/json' } });
            const data = response.data;
            //console.log('categories: ', response.data);
            setCategories(data.categories);
            setCatCount(data.categories.length);
        } catch (error) {
            console.log('Error occurred while fetching categories. :', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchSearchCategories = async (searchTerm) => {
        try {
            //console.log('[Search] categories: ', categories);
            //console.log('[Search] searchTerm: ', searchTerm);
            if (searchTerm === '' || searchTerm === 'undefined') {
                fetchCategories();
            } else {
                const filteredCategories = categories.filter(category => category.categoryType.toLowerCase().startsWith(searchTerm.toLowerCase()));
                setCategories(filteredCategories);
                //console.log('filteredCategories: ', filteredCategories);
            }
        } catch (error) {
            console.log('Error occurred while fetching categories. :', error);
        }
    }

    return (<CatContext.Provider value={{
        loading,
        catCount,
        categories,
        fetchCategories,
        fetchSearchCategories
    }}>
        {props.children}
    </CatContext.Provider>);
}

export default CategoryState;
