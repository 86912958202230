import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DocContext from '../reduxContainer/context/DocContext';

const Item = (props) => {
    const navigate = useNavigate();
    const docContext = useContext(DocContext);
    const { fetchDocuments } = docContext;

    const loadDocuments = async (e) => {
        e.preventDefault();
        await fetchDocuments(props.category);
        navigate('/dataContent');
    }
    return (
        <div>
            <div className="cardBox card item">
                <div className="card-body">
                    <h6 className="itemtitle card-title"><b>{props.category}</b></h6>
                    <p className="itemtext card-text"><small>{props.description}</small></p>
                    <Link to="/dataContent" className="itemlink btn btn-success" onClick={(e) => loadDocuments(e)}>{props.category} - Documents</Link>
                </div>
            </div>
        </div>
    );
}

export default Item;
