import React, { useState } from 'react';
import axios from 'axios';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';

const CategoryUploader = () => {
    const [category, setCategory] = useState('Triveni');
    const [description, setDescription] = useState('');
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
    const alertContext = useContext(AlertContext);
    const {showAlert} = alertContext;

    const handleUpload = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('categoryType', category);
        formData.append('description', description);
        const result = await axios.post(`${host}/category`, formData, { headers: { 'content-type': 'application/json' } });
        console.log(result.data);
        if(result.status === 201) {
            showAlert(`${category} category is successfully uploaded.`, "success");
        } else {
            showAlert(`${result.error}`, "danger");
        }
        clearFormData(e);
    }

    const clearFormData = (e) => {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
    }

    return (
        <div className='container'>
            <form method='POST' encType="multipart/form-data" onSubmit={handleUpload} className='fileUploadForm'>
                <div className="fileUploadForm">
                    <label htmlFor="category" className="label form-label"><span className="label other">Category</span></label>
                    <input type="text" className="form-control mb-3" id="category" name="category" onChange={(e) => setCategory(e.target.value)} placeholder='Enter Folder Name' required/>
                    <label htmlFor="description" className="label form-label"><span className="label other">Description</span></label>
                    <input type="text" className="form-control mb-3" id="description" name="description" onChange={(e) => setDescription(e.target.value)} placeholder='Enter details of Category.' required/>
                    <button type="submit" className="btn btn-success mb-3">Add</button>
                </div>
            </form>
        </div>
    );
}

export default CategoryUploader;
