import React from 'react';
import LeftSideBar from '../components/LeftSideBar';
import RightSideBar from '../components/RightSideBar';
import MiddleDataContentBar from './MiddleDataContentBar';

const MiddleDataContentArea = () => {
    return (
        <div className="middleBar container-fluid my-2">
            <div className="row content">
                <LeftSideBar />
                <MiddleDataContentBar />
                <RightSideBar />
            </div>
        </div>
    );
}

export default MiddleDataContentArea;
