import React, { useEffect, useState } from 'react';
import CatContext from '../reduxContainer/context/CategoryContext';
import { useContext } from 'react';
import axios from 'axios';

const InfoCounter = () => {
  const catContext = useContext(CatContext);
  let { catCount } = catContext;
  const [docCount, setDocCount] = useState(0);
  const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await axios.post(`${host}/document/getTotalDocumentCount`, { headers: { 'content-type': 'application/json' } });
      if (result.status === 200) {
        setDocCount(result.data.length);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCount]);

  if (loading) {
    return (<div className="spinner-border text-danger" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>)
  } else {
    return (
      <div>
        <div className="fileUploadForm d-flex">
          <label className="label form-label"><span className="label other">Categories</span></label>
          <label className="label form-label bg-success"><b>{catCount}</b></label>
          <label className="label form-label"><span className="label other">&nbsp;Documents</span></label>
          <label className="label form-label bg-success"><b>{docCount}</b></label>
        </div>
      </div>
    );
  }
}

export default InfoCounter;
