import React, { useContext, useEffect } from 'react';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import Footer from '../components/Footer';
import AboutMiddle from './AboutMiddle';
import Alert from '../components/Alert';
import AlertContext from '../reduxContainer/context/AlertContext';

const About = () => {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const alertContext = useContext(AlertContext);
  const { alert } = alertContext;
  return (
    <div>
      <Header />
      {alert && (<Alert />)}
      {!alert && (<SearchBar />)}
      <AboutMiddle />
      <Footer />
    </div>
  );
}

export default About;
