import React from 'react';
import { jsPDF } from "jspdf";

const PdfDocument = (props) => {

  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setFont('Times');
    doc.setFontSize(10);

    //console.log('props: ', props);
    doc.setFontSize(14);
    var yAxis = 10;
    doc.text("Salary and Allowances\n", 10, yAxis);
    doc.setFontSize(10);
    doc.text("\n\tBasic Salary: " + props.basicSal, 10, yAxis = yAxis+8);
    doc.text("\n\tDA: " + props.da, 10, yAxis = yAxis+8);
    doc.text("\n\tHRA: " + props.hra, 10, yAxis = yAxis+8);
    doc.text("\n\tTransport Allowance(TA): " + props.ta, 10, yAxis = yAxis+8);
    doc.text("\n\tDA On TA: " + props.daOnTa, 10, yAxis = yAxis+8);
    doc.text("\n\tOther Allowances: " + props.otherAllowances, 10, yAxis = yAxis+8);
    doc.setFontSize(12);
    doc.text("\tGross Salary: " + props.grossSalary, 10, yAxis = yAxis+16);

    doc.setFontSize(14);
    doc.text("\nDeductions\n", 10, yAxis = yAxis+8);
    doc.setFontSize(10);
    doc.text("\n\tNPS/GPF: " + props.npsGpf, 10, yAxis = yAxis+8);
    doc.text("\n\tGIS: " + props.gis, 10, yAxis = yAxis+8);
    doc.text("\n\tOther Deductions: " + props.otherDeductions, 10, yAxis = yAxis+8);
    doc.setFontSize(12);
    doc.text("\tNet Salary: " + props.netSalary, 10, yAxis = yAxis+16);

    doc.save("SalarySlip.pdf");
  }

  return (
    <div>
      <div className="btn btn-success" onClick={generatePdf} >Download Salary Slip pdf</div>
    </div>
  );
}


export default PdfDocument;
