import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './home/Home';
import SignUp from './screens/SignUp';
import Login from './screens/Login';
import Dashboard from './dashboard/Dashboard';
import DataContent from './datacontent/DataContent';
import DocState from './reduxContainer/context/DocState';
import CatState from './reduxContainer/context/CategoryState';
import AlertState from './reduxContainer/context/AlertState';
import About from './about/About';
import Contact from './components/Contact';
import Privacy from './screens/Privacy';
import Important from './ImportantData/Important';
import SalaryCalculator from './calculator/salary/SalaryCalculator';

function App() {
  return (
    <CatState>
      <DocState>
        <AlertState>
        <div className=" container-fluid App">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/signin" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dataContent" element={<DataContent />} />
                <Route path="/about" element={<About />} />
                <Route path="/contactus" element={<Contact />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/topdocuments" element={<Important />} />
                <Route path="/salcalculator" element={<SalaryCalculator />} />
              </Routes>
            </BrowserRouter>
          </div>
        </AlertState>
      </DocState>
    </CatState>
  );
}

export default App;
