import React, { useContext } from 'react';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import Footer from '../components/Footer';
import MiddleDataContentArea from './MiddleDataContentArea';
import Alert from '../components/Alert';
import './dataContent.css';
import AlertContext from '../reduxContainer/context/AlertContext';


const DataContent = () => {
  const alertContext = useContext(AlertContext);
  const { alert } = alertContext;
  return (
    <div>
      <div>
        <Header />
        {alert && (<Alert />)}
        {!alert && (<SearchBar />)}
        <MiddleDataContentArea />
        <Footer />
      </div>
    </div>
  );
}

export default DataContent;
