import React from 'react';
import SalaryCalculatorMain from './SalaryCalculatorMain';

const SalaryCalculatorContentBar = () => {
    return (
        <div className="col-sm-8">
            <SalaryCalculatorMain />
        </div>
      );
}

export default SalaryCalculatorContentBar;
