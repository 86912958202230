import React from 'react';
import ListItems from './ListItems';

const ContentBar = () => {
  return (
    <div className="col-sm-8">
        <ListItems />
    </div>
  );
}

export default ContentBar;
