import React, { useContext } from 'react';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import Footer from '../components/Footer';
import MiddleDashBoardArea from './MiddleDashBoardArea';
import Alert from '../components/Alert';
import AlertContext from '../reduxContainer/context/AlertContext';

const Dashboard = () => {
  const alertContext = useContext(AlertContext);
  const { alert } = alertContext;
  return (
    <div className='dashboardHome'>
      <Header />
      {alert && (<Alert />)}
      {!alert && (<SearchBar />)}
      <MiddleDashBoardArea />
      <Footer />
    </div>
  );
}

export default Dashboard;
