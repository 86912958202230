import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Alert from '../components/Alert';

const SignUp = () => {
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;
    const [credentials, setCredentials] = useState({ email: "", firstName: "", lastName: "", password: "", cpassword: "" });
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const { email, firstName, lastName, password } = credentials;
            let formData = new FormData();
            formData.append('email', email);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('password', password);
            const response = await axios.post(`${host}/auth/signup`, formData, { headers: { 'content-type': 'application/json' } });
            const data = response.data;
            console.log('User created:  ', data);
            if (response.status === 201) {
                showAlert(`User is successfully created`, "success");
                navigate('/');
            } else {
                showAlert(`${data.error}`, "danger");
            }
        } catch (error) {
            showAlert(`Internal server error`, "danger");
        }

    }

    const handleChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });
    };

    return (
        <>
        <div className = "positionMiddle">
            <Navbar />
            <Alert />
            <div className='loginPage container border border-secondary'>
                <form method='POST' onSubmit={handleSubmit}>
                <h4 className="SubHeading fs-4 p-1 centre text-light mt-2 mb-2">Enter details to create an Account</h4>
                    <div className="mb-2 mt-3">
                        <input type="email" className="form-control" id="email" name="email" placeholder='Enter Email *' onChange={handleChange} required />
                    </div>
                    <div className="mb-2">
                        <input type="text" className="form-control" id="firstName" name="firstName" placeholder='Your First Name *' onChange={handleChange} required />
                    </div>
                    <div className="mb-2">
                        <input type="text" className="form-control" id="lastName" name="lastName" placeholder='Your Last Name *' onChange={handleChange} required />
                    </div>
                    <div className="mb-2">
                        <input type="password" className="form-control" id="password" name="password" placeholder='Your Password *' onChange={handleChange} required />
                    </div>
                    <div className='centre'>
                        <button type="submit" className="btn btn-success my-3">Sign Up</button>
                        <Link type="button" className="btn btn-success mx-3 my-3" to="/login">Login</Link>
                        <Link type="button" className="btn btn-success my-3" to="/">Home</Link>
                    </div>
                </form>
                </div>
                <div className="alignBottom">
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default SignUp;
