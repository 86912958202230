import { React, useContext, useEffect } from 'react';
import Item from './Item';
import CatContext from '../reduxContainer/context/CategoryContext';
import ScrollToTop from "react-scroll-to-top";

const ListItems = () => {
    const catContext = useContext(CatContext);
    let { loading, categories, fetchCategories } = catContext;

    useEffect(() => {
        (async () => {
            await fetchCategories();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className='centre contentBar row'>
                {loading && <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
                {categories.map((element) => {
                    return <div className="col-xs-6 col-md-4 col-sm-4" key={element._id}>
                        <Item category={element.categoryType ? element.categoryType : ""} description={element.description ? element.description.slice(0, 88) : ""} />
                    </div>
                })}
            </div>
            <div>
                <ScrollToTop smooth />
            </div>
        </div>
    );
}

export default ListItems;
