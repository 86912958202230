import React, { useContext } from 'react';
import Alert from '../components/Alert';
import AlertContext from '../reduxContainer/context/AlertContext';
import ImportantMiddle from './ImportantMiddle';
import SearchBar from '../components/SearchBar';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Important = () => {
  const alertContext = useContext(AlertContext);
  const { alert } = alertContext;
    return (
        <div>
          <Header />
          {alert && (<Alert />)}
          {!alert && (<SearchBar />)}
          <ImportantMiddle />
          <Footer />
        </div>
      );
}

export default Important;
