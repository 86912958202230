import React from 'react';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';

const SocialShare = (props) => {
    var url = props.url;
    const encodedURL = encodeURI(url);
    //console.log('encodedURL: ', encodedURL);
    //console.log('title: ', props.title);
    return (
        <div>
            <WhatsappShareButton url={encodedURL} title={`${props.title}`} separator = "=> ">
                <WhatsappIcon size={20} logoFillColor='white' round={true} />
            </WhatsappShareButton>
        </div>
    );
}

export default SocialShare;
