import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

const AboutMain = () => {
  return (
    <div className="aboutText">
      <h3>About Us</h3>
      <hr />
      <p><Link className="green" to="/">DaoAssist</Link> helps assist accountants, DAO, Auditors in performing the complex duties
        related to PWD accounting. It is a one-stop solution for all the requisite documents
        which are from time to time issued by UP Govt, GOI, and other government departments.
      </p>
      <p>It comprises a combined repository compartmentalized into categories (folders) that combines from various sources&nbsp;
        <u>Govt Orders, notifications, Shasanadesh(s)</u> etc, allows users to download, share,
        read and upload documents.</p>
      <p>This Website provides in-page and in-repository Search functionality across all pages
        to help users search Govt Orders, notifications, Shasanadesh(s) etc.
      </p>
      <p>It also allows users to upload, delete and update <u>Govt Orders, notifications, Shasanadesh(s)</u> etc into organised repositories.  &nbsp;
        <b />For now, this feature is restricted to Administrator only.
      </p>
      <Link className='btn btn-success' to="/">Click here to View All documents</Link>
      <hr />
      <div>
        <ScrollToTop smooth />
      </div>
    </div>
  );
}

export default AboutMain;
