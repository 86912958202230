import React, { useContext, useState } from 'react';
import CatContext from '../reduxContainer/context/CategoryContext';
import DocContext from '../reduxContainer/context/DocContext';
import { Link, useNavigate } from 'react-router-dom';
import AlertContext from '../reduxContainer/context/AlertContext';

const SearchBar = () => {
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const catContext = useContext(CatContext);
    const docContext = useContext(DocContext);
    let { fetchSearchCategories } = catContext;
    let { fetchSearchDocuments, searchRepositoryDocuments } = docContext;

    const handleInputChange = async (event) => {
        //console.log('searchTerm: ', event.target.value);
        setSearchTerm(event.target.value);
        await fetchSearchCategories(event.target.value);
        await fetchSearchDocuments(event.target.value);
    }

    const handleRepositorySearch = async (e) => {
        //console.log('Hi searchTerm: ', searchTerm);
        e.preventDefault();
        if (searchTerm === '' || searchTerm ==='undefined') {
            //console.log('Hi Neeraj: ', searchTerm);
            showAlert(`Blank Search is not allowed. Try Again.`, "danger");
        } else {
            await searchRepositoryDocuments(searchTerm);
            navigate('/dataContent');
        }
    }

    return (
        <div className='searchBar row'>
            <form id = "searchForm" className="searchBarForm" role="search" onSubmit={(e) => handleRepositorySearch(e)}>
                <input className="form-control me-2" type="search" value={searchTerm} onInput={(event) => handleInputChange(event)} placeholder="Search Order, Document, Notification" aria-label="Search" />
                <Link id="searchId" type="submit" className="btn btn-success searchBarFormLink" onClick={(e) => handleRepositorySearch(e)}>Search&nbsp;in&nbsp;Repository</Link>
            </form>
        </div>
    );
}

export default SearchBar;
