import React, { useState } from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';
import Alert from './Alert.js';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';

const Contact = () => {
    const [msgName, setMsgName] = useState("");
    const [msgEmail, setMsgEmail] = useState("");
    const [msgPhone, setMsgPhone] = useState("");
    const [txtMsg, setTxtMsg] = useState("");

    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
    const alertContext = useContext(AlertContext);
    const { alert, showAlert } = alertContext;

    const handleFeedback = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('msgName', msgName);
        formData.append('msgEmail', msgEmail);
        formData.append('msgPhone', msgPhone);
        formData.append('txtMsg', txtMsg);
        const result = await axios.post(`${host}/contact/saveFeedback`, formData, { headers: { 'content-type': 'application/json' } });
        console.log(result.data);
        if (result.status === 201) {
            showAlert(`Your feedback is successfully recorded.`, "success");
        } else {
            showAlert(`${result.error}`, "danger");
        }
        clearFormData(e);
    }

    const clearFormData = (e) => {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
        document.getElementById('txtMsg').value = '';
    }

    return (
        <div>
            <Navbar />
            {alert && (<Alert />)}
            {!alert && (<SearchBar />)}
            <div className="container positionMiddle mt-2">
                <form className="contactForm" method="post" onSubmit={handleFeedback}>
                    <div className="centre SubHeading p-1 mb-2">
                        <h3>Drop Us a Message</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-2">
                                <input type="text" name="msgName" id="msgName" className="form-control" placeholder="Your Name *" onChange={(e) => setMsgName(e.target.value)} required />
                            </div>
                            <div className="form-group mb-2">
                                <input type="text" name="msgEmail" id="msgEmail" className="form-control" placeholder="Your Email *" onChange={(e) => setMsgEmail(e.target.value)} required />
                            </div>
                            <div className="form-group mb-2">
                                <input type="text" name="msgPhone" id="msgPhone" className="form-control" placeholder="Your Phone Number *" onChange={(e) => setMsgPhone(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input type="submit" name="btnSubmit" className="btn btn-success mb-2" value="Send Message" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <textarea name="txtMsg" id="txtMsg" className="form-control" placeholder="Your Message *" style={{ width: '100%', height: '150px' }} onChange={(e) => setTxtMsg(e.target.value)} required></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="alignBottom">
                <Footer />
            </div>
        </div>
    );
}

export default Contact;
