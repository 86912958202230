import React from 'react';
import Navbar from './Navbar';

const Header = () => {
  return (
    <div>
        <Navbar/>
    </div>
  );
}

export default Header;
