import { useState } from "react";
import AlertContext from "./AlertContext";

const AlertState = (props) => {
    let initialAlert = null;
    const [alert, setAlert] = useState(initialAlert);

    const showAlert = (message, type) => {
        setAlert({
            message: message,
            type: type
        });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    };

    return (<AlertContext.Provider value={{ alert, showAlert }}>
        {props.children}
    </AlertContext.Provider>);
}

export default AlertState;
