import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AlertContext from '../reduxContainer/context/AlertContext';
import CatContext from '../reduxContainer/context/CategoryContext';
import { useContext } from 'react';
import CategorySelector from './CategorySelector';

const FileUploader = () => {
    const [selectValue, setSelectValue] = useState("");
    const [files, setFiles] = useState([]);
    let [options, setOptions] = useState([]);

    const catContext = useContext(CatContext);
    let { loading, categories, fetchCategories } = catContext;

    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;

    // eslint-disable-next-line
    useEffect(() => {
        (async () => {
            await fetchCategories();
            populateSelect();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleFiles = (e) => {
        const files1 = e.target.files;
        let newArr = [];
        for (let i = 0; i < files1.length; i++) {
            newArr.push(files1[i]);
        }
        setFiles([]);
        setFiles(newArr);
    }

    const handleUpload = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('category', selectValue);
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        const result = await axios.post(`${host}/file/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        clearFormData(e);
        window.scrollTo(0, 0);
        if (result.status === 201) {
            showAlert(`${result.data.length} file(s) are successfully uploaded.`, "success");
        } else {
            showAlert(`${result.error}`, "danger");
        }
    }

    const populateSelect = () => {
        setOptions(null);
        const catOptions = [];
        categories.forEach((cat) => {
            let value = cat.categoryType;
            let label = cat.categoryType;
            catOptions.push({ value: value, label: label });
        });
        setOptions(catOptions);
        return options;
    }

    const clearFormData = (e) => {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
    }

    if (loading) {
        return (<center><div><div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
        </div></div></center>)
    } else {
        return (
            <div className='container'>
                <form method='POST' encType="multipart/form-data" onSubmit={handleUpload} className='fileUploadForm'>
                    <div className="fileUploadForm mb-1">
                        <input type="file" className="form-control mb-3" id="files" name="files" onChange={(e) => handleFiles(e)} multiple required />
                        <CategorySelector selectValue={selectValue} setSelectValue={setSelectValue} />
                        <button type="submit" className="btn btn-success mb-3">Upload</button>
                    </div>
                </form>
            </div>
        );
    }

}

export default FileUploader;