import React, { useState } from 'react';
import axios from 'axios';
import AlertContext from '../reduxContainer/context/AlertContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
/* import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode"; */

const Login = (props) => {
    const alertContext = useContext(AlertContext);
    const { showAlert } = alertContext;
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            const response = await axios.post(`${host}/auth/login`, formData, { headers: { 'content-type': 'application/json' } });
            const data = response.data;
            if (response.status === 200) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', data.user.firstName);
                showAlert(`Successfully logged in.`, "success");
                if (props.previousPage === '/') {
                    navigate('/');
                } else {
                    navigate(props.previousPage);
                }
            } else {
                showAlert(`${data.error}`, "danger");
            }
        } catch (error) {
            showAlert(`Invalid Credentials`, "danger");
        }
    }
    return (
        <div className={props.positionMiddle}>
            <NavBar />
            {!props.excludeAlert && <Alert />}
            <div className={`container centre loginPage mt-${props.marginTop} w-${props.width} border border-secondary`}>
                <form method='POST' onSubmit={handleSubmit}>
                <h2 className='SubHeading fs-3 p-1 centre text-light mb-2 mt-2'>Enter your credentials</h2>
                    <div className="mb-4 mt-3">
                        <input type="email" className="form-control" placeholder='Enter your Email Address' id="email" name="email" required onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-2">
                        <input type="password" className="form-control" placeholder='Enter your Password' id="password" name="password" required onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div>
                        <button type="submit" className="btn btn-success">Sign In</button>
                        <Link type="button" className="btn btn-success mx-3 my-3" to="/signup">Sign Up</Link>
                        <Link type="button" className="btn btn-success my-3" to="/">Home</Link>
                    </div>
                </form>
                {/* <div className="my-2" style={{ width: '200px', marginLeft: `${props.marginLeft}px` }}>
                    <GoogleOAuthProvider clientId="443918688008-816g51ed5n75r6098jdcc809r2fbchcs.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                const decoded = jwtDecode(credentialResponse.credential);
                                if (decoded.email_verified === true)
                                    navigate('/');
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }} />
                    </GoogleOAuthProvider>
                </div> */}
            </div>
            <div className="alignBottom">
            {!props.excludeAlert && <Footer />}
            </div>
        </div>
    );
}

// Setting default value for props
Login.defaultProps = {
    width: 80,
    marginTop: 3,
    previousPage: '/',
    excludeAlert: false,
    marginLeft: 49,
    excludeFooter: false,
    positionMiddle: 'positionMiddle'
};

export default Login;
