import React from 'react';
import ContentBar from './ContentBar';
import LeftSideBar from '../components/LeftSideBar';
import RightSideBar from '../components/RightSideBar';

const MiddleBar = () => {
    return (
        <div className="middleBar container-fluid text-center my-1">
            <div className="row content">
                <LeftSideBar />
                <ContentBar />
                <RightSideBar />
            </div>
        </div>
    );
}

export default MiddleBar;
