import React, { useContext } from 'react';
import SalaryCalculatorMiddle from './SalaryCalculatorMiddle';
import AlertContext from '../../reduxContainer/context/AlertContext';
import Header from '../../components/Header';
import Alert from '../../components/Alert';
import SearchBar from '../../components/SearchBar';
import Footer from '../../components/Footer';

const SalaryCalculator = () => {
    const alertContext = useContext(AlertContext);
    const { alert } = alertContext;
    return (
        <div>
            <Header />
            {alert && (<Alert />)}
            {!alert && (<SearchBar />)}
            <SalaryCalculatorMiddle />
            <Footer />
        </div>
    );
}

export default SalaryCalculator;
