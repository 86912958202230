import DocContext from "./DocContext";
import axios from 'axios';
import { useState } from "react";

const DocState = (props) => {
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
    let initialDocs = [];
    const [documents, setDocuments] = useState(initialDocs);
    const [allDocs, setAllDocs] = useState(initialDocs);
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchDocuments = async (category) => {
        try {
            setCategory(category);
            let formData = new FormData();
            formData.append('type', category);
            const response = await axios.post(`${host}/document/getAllDocsByType`, formData, { headers: { 'content-type': 'application/json' } });
            const data = response.data;
            setDocuments(data.documents);
            setAllDocs(data.documents);
        } catch (error) {
            console.log('Error occurred while fetching documents. :', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchSearchDocuments = async (searchTerm) => {
        try {
            //console.log('[Search] searchTerm: ', searchTerm);
            if (searchTerm === '' || searchTerm === 'undefined') {
                setDocuments(allDocs);
            } else {
                const filteredDocuments = documents.filter(document => document.fileName.toLowerCase().includes(searchTerm.toLowerCase()));
                setDocuments(filteredDocuments);
                //console.log('filteredDocuments: ', filteredDocuments);
            }
        } catch (error) {
            console.log('Error occurred while searching documents. :', error);
        }
    }

    const searchRepositoryDocuments = async (searchTerm) => {
        try {
            //console.log('[Search] searchTerm: ', searchTerm);
            if (searchTerm === '' || searchTerm === 'undefined') {
                setDocuments(allDocs);
            } else {
                let formData = new FormData();
                formData.append('fileName', searchTerm.toString());
                const response = await axios.post(`${host}/document/search`, formData, { headers: { 'content-type': 'application/json' } });
                const data = response.data;
                setDocuments(data.documents);
                //console.log('repositoryDocuments: ', data.documents);
            }
        } catch (error) {
            console.log('Error occurred while searching repository documents. :', error);
        }
    }

    return (<DocContext.Provider value={{
        loading,
        category,
        documents,
        fetchDocuments,
        fetchSearchDocuments,
        searchRepositoryDocuments
    }}>
        {props.children}
    </DocContext.Provider>
    );
}

export default DocState;
